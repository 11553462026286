import React, { useContext, useState, useEffect } from "react"
import { Link } from "gatsby"
import Seo from "../../components/seo"
import * as styles from "./index.module.scss"
import { CartContext } from "../../contexts/Cart"
import { StoreContext } from "../../contexts/Store"
import Breadcrumbs from "../../components/Breadcrumbs"
import { GrTrash } from "react-icons/gr"
import currencyFormat from "../../utils/currencyFormat"
import { toast } from "react-toastify"
import CheckoutForm from "./_CheckoutForm"
import { BiHappyAlt } from "react-icons/bi"

const CartPage = () => {
  const {
    cart: { items },
  } = useContext(CartContext)

  const [success, setSuccess] = useState(false)

  return (
    <>
      <Seo title="Warenkorb" />

      <Breadcrumbs title={"Warenkorb"} />

      {success ? (
        <div className="container my-4 py-4">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
              <span className={"text-primary"}>
                <BiHappyAlt size={60} />
              </span>
              <h4 className={"mt-4 text-primary"}>Erfolg.</h4>
              <p>
                Wir haben Ihre Bestellung erhalten. Einer unserer Agenten wird
                sich in Kürze mit Ihnen in Verbindung setzen.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          {items.length === 0 && !success ? (
            <CartEmpty />
          ) : (
            <Cart setSuccess={() => setSuccess(true)} />
          )}
        </>
      )}
    </>
  )
}

const Cart = ({ setSuccess = () => null }) => {
  const [method, setMethod] = useState(0)

  const {
    cart: { items },
    actions: { removeFromCart },
  } = useContext(CartContext)

  const {
    store: { products },
  } = useContext(StoreContext)

  const deleteItem = (id, name) => {
    removeFromCart(id)
    setMethod(0)
    toast.warning(`${name} wurde entfernt.`)
  }

  const [subtotal, setSubtotal] = useState(0)
  const [shipping, setShipping] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (!items || items.length === 0 || products.length === 0) {
      setShipping(0)
      setTotal(0)
      setSubtotal(0)
      return
    }
    let st = 0
    for (let i = 0; i < items.length; i++) {
      const cartItem = items[i]
      const product = products.find(product => product.id === cartItem.id)
      // const product = items[i];
      st += Number(product.selling_price) * cartItem.amount
    }
    setSubtotal(st)

    if (st < 30) {
      setShipping(9.9)
      setTotal(st + 9.9)
    } else {
      setShipping(0)
      setTotal(st)
    }
  }, [items, products, items.length])

  if (products.length === 0) {
    return (
      <div className="container mt-5 pt-4">
        <p className="text-center">Loading...</p>
      </div>
    )
  }

  return (
    <div className="container mt-5 pt-4">
      <div className="row">
        <div className="col-md-6">
          <h5>Warenkorb-Übersicht</h5>
          <ul className={styles.cart_list}>
            {products &&
              items &&
              items.length > 0 &&
              items.map((item, idx) => {
                const product = products.find(product => product.id === item.id)
                if (!product) return null

                return (
                  <li key={idx}>
                    <img
                      src={
                        require(`../../images/products/${product.id}.jpg`)
                          .default
                      }
                      alt={product.name}
                    />
                    <p>
                      <span>{product.name}</span>
                      <span>
                        {currencyFormat(product.selling_price)} x {item.amount}
                      </span>
                    </p>{" "}
                    <span>
                      {currencyFormat(item.amount * product.selling_price)}
                      <button
                        className={"btn btn-default btn-sm"}
                        onClick={() => deleteItem(product.id, product.name)}
                      >
                        <GrTrash />
                      </button>
                    </span>
                  </li>
                )
              })}
          </ul>

          <ul className={styles.cart_summary}>
            <li>
              <span>Zwischensumme</span>
              <span>{currencyFormat(subtotal)}</span>
            </li>
            <li>
              <span>Shipping</span>
              <span>{currencyFormat(shipping)}</span>
            </li>
            <li>
              <span>Gesamtsumme</span>
              <span>{currencyFormat(total)}</span>
            </li>
          </ul>
        </div>
        <div className="col-md-6 col-xl-5 offset-xl-1">
          <CheckoutForm
            setSuccess={() => setSuccess(true)}
            total={total}
            method={method}
            setMethod={setMethod}
          />
        </div>
      </div>
    </div>
  )
}

const CartEmpty = () => {
  return (
    <div className={"container text-center mt-5 pt-5"}>
      <h5>Your cart is empty</h5>
      <p>Please go to our Organic Store and fill your basket.</p>
      <Link to={"/organic-store"} className={"btn btn-primary"}>
        Go to Organic Store
      </Link>
    </div>
  )
}

export default CartPage
