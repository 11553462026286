import React, { useContext, useState } from "react"
import api from "../../utils/api"
import { toast } from "react-toastify"
import { CartContext } from "../../contexts/Cart"
import * as styles from "./index.module.scss"
import PaypalButton from "./PaypalButton"

// const PayPalButton = typeof window === "undefined" ? {} : window.paypal.Buttons.driver("react", { React, ReactDOM });

const isDev = value => (process.env.NODE_ENV === "development" ? value : "")

const defaultUserState = {
  first_name: isDev("Mahesh"),
  last_name: isDev("Samudra"),
  email: isDev("mahesh@owita.lk"),
  address: isDev("No. 123, Temple Road"),
  phone: isDev("081728374"),
  city: isDev("Colombo"),
}

const CheckoutForm = ({
  setSuccess = () => null,
  total = 0,
  method = 0,
  setMethod = () => null,
}) => {
  const [user, setUser] = useState(defaultUserState)

  const [progress, setProgress] = useState(false)

  const {
    cart: { items },
    actions: { clearCart },
  } = useContext(CartContext)

  const setValue = (name, value) => {
    setMethod(0)
    setUser({
      ...user,
      [name]: value,
    })
  }

  const isInvalid =
    !user.first_name ||
    !user.last_name ||
    !user.email ||
    !user.address ||
    !user.phone ||
    !user.city

  const submit = async (e, paymentId) => {
    setProgress(true)
    if (e) {
      e.preventDefault()
    }
    console.log("validating", paymentId)
    if (
      !user.first_name ||
      !user.last_name ||
      !user.email ||
      !user.address ||
      !user.phone ||
      !user.city
    ) {
      toast.warning(`Alle Felder sind erforderlich.`)
      return
    }

    console.log("submitting")

    await api("orders/new", {
      user,
      items,
      paymentId,
    })
      .then(res => {
        clearCart()
        setSuccess()
      })
      .catch(error => {
        toast.warning(`Fehler beim Absenden Ihrer Bestellung`)
        console.log(error)
      })
  }

  return (
    <div className={"container mt-5 mt-lg-0"}>
      <h5>Kontakt Informationen</h5>
      <div className="row">
        <div className="col-sm-6">
          <Input
            label="Vorname *"
            value={user.first_name}
            setValue={value => setValue("first_name", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Name *"
            value={user.last_name}
            setValue={value => setValue("last_name", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Email *"
            value={user.email}
            setValue={value => setValue("email", value)}
          />
        </div>
        <div className="col-sm-6">
          <Input
            label="Telefonnummer"
            value={user.phone}
            setValue={value => setValue("phone", value)}
          />
        </div>
      </div>

      <h5 className="mt-3">Versandinformationen</h5>
      <div className="row">
        <div className="col-sm-12">
          <Input
            label="Adresse"
            value={user.address}
            setValue={value => setValue("address", value)}
          />
        </div>
        <div className="col-sm-12">
          <Input
            label="Stadt"
            value={user.city}
            setValue={value => setValue("city", value)}
          />
        </div>

        <div className="col-sm-12 mb-3">
          <label htmlFor="">Payment Method</label>

          <br />
          <label htmlFor="" onClick={() => setMethod(0)}>
            <input type="radio" readOnly checked={method === 0} /> Bank Transfer
          </label>
          <br />
          <label htmlFor="" onClick={() => setMethod(1)}>
            <input type="radio" readOnly checked={method === 1} /> PayPal
          </label>
        </div>

        <div className="col-sm-12">
          {method === 0 ? (
            <button
              className={`btn btn-primary me-auto w-100 ${styles.submit_button}`}
              disabled={progress || isInvalid}
              onClick={submit}
            >
              {progress ? <Loading /> : "Zur Kasse"}
            </button>
          ) : (
            <div>
              {progress ? (
                <button
                  className={`btn btn-primary me-auto w-100 ${styles.submit_button}`}
                  disabled={true}
                >
                  <Loading />
                </button>
              ) : (
                <PaypalButton
                  total={total}
                  submit={submit}
                  isInvalid={isInvalid}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
const Loading = () => (
  <span
    className="spinner-border spinner-border-sm"
    role="status"
    aria-hidden="true"
  />
)

const Input = ({ label = "", value = "", setValue = () => null }) => {
  return (
    <div className="mb-3">
      <label htmlFor="">{label}</label>
      <input
        type="text"
        className="form-control"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  )
}

export default CheckoutForm
